
import $ from 'jquery'
import '../styles/main.scss'

class Questionnaire {
  constructor() {
    this.currentStep = 0
    this.formData = []
    this.sendFormWithData = 0
    this.steps = []
    this.specialStep = ''

    this.setStep(0)
    this.clickOnFormButton()
    this.clickOnStartButton()
    this.clickToForm()
    this.clickOnFormSendButton()
    this.resetForm()
    this.clickOnHomeButton()
  }

  clickOnHomeButton() {
    $(document).on('click', '.header__icon', () => {
      this.resetForm()
    })
  }

  resetForm() {
      this.setStep(0);
      this.formData = [];
      this.sendFormWithData = 0;
      this.steps = [];
      this.specialStep = '';
      const $formProcessElement = $('div.form-process ul')
      $formProcessElement.html('')
      $(`.brands__category`).removeClass('active')
  }

  setStep( step ) {
    this.currentStep = step
    $('html body').attr('data-step', step)
    this.displayStep()
  }

  gatherFormData(element) {
    const answerCategory = $(`div.step[data-step="${this.currentStep}"]`).attr('data-category')
    const answer = $(element).attr('data-answer')

    this.formData.push(`> ${answerCategory}: ${answer}`)
  }

  mapAndDisplayFormDataInProcess() {
    if ( this.formData.length > 0 ) {
      const $formProcessElement = $('div.form-process ul')
      $formProcessElement.html('')
      this.formData.map(string => {
        $formProcessElement.append(`<li data-type="${string}">${string}</li>`)
      })
    }
  }

  displayStep() {
    $('div.step, div.index').attr('style', 'display: none')
    if ( parseInt(this.currentStep) === 0 ) {
      $(`div.index[data-step="${this.currentStep}"]` ).css({display: 'inline-block'})
    } else {
      $(`div.step[data-step="${this.currentStep}"]` ).css({display: 'inline-block'})
    }
  }

  renderRightBrandCategory( category ) {
    $(`.brands__category[data-final="${category}"]`).addClass('active')
  }

  clickOnStartButton() {
    $(document).on('click', 'button.start-button', (e) => {
      e.preventDefault()
      const $e = $(e.currentTarget)
      const goToStep = $e.attr('data-goto')
      this.resetForm()
      this.setStep(goToStep)
    })
  }

  clickToForm() {
    $(document).on('click', 'a[data-goto="form"]', (e) => {
      e.preventDefault()
      const $e = $(e.currentTarget)
      const goToStep = $e.attr('data-goto')
      const sendWithData = $e.attr('data-formdata')
      this.sendFormWithData = sendWithData
      if (parseInt(this.sendFormWithData) === 1) {
        $('form').attr('data-with-data', 1);
      }
      this.setStep(goToStep)
    })
  }

  traceSteps(step) {
    this.steps.push(step)
  }

  clickOnFormButton() {
    $(document).on('click', '.step__answer button', (e) => {
      e.preventDefault()
      const $e = $(e.currentTarget)
      const goToStep = $e.attr('data-goto')
      const dataAnswer = $e.attr('data-answer')
      const specialStep = $e.attr('data-special-step')
      const specialContent = $e.attr('data-special')
      const dataShort = $e.attr('data-short')
      this.gatherFormData( $e )
      this.setStep(goToStep)

      if ( goToStep === 'final' ) {
        const activeCategory = $e.attr('data-active-category')
        this.renderRightBrandCategory(activeCategory)
        if (this.steps.length == 3) {
          this.formData.forEach((item, key) => {
            if (item === '> Use of crane: No' && dataShort === 'considered') {
              this.formData[key] = `${item} ${specialContent}`
            }
          })
        } else if (this.steps.length === 2) {
          this.formData.forEach((item, key) => {
            if (item === '> Use of crane: Yes' && ( dataShort === 'business' || dataShort === 'different')) {
              this.formData[key] = `${item} ${specialContent}`
            }
          })
        }
      }

      if (specialStep) {
        this.specialStep = specialStep
      }

      this.traceSteps(goToStep)
      this.mapAndDisplayFormDataInProcess()
    })
  }

  clickOnFormSendButton() {
    $(document).on('click', 'button.send-contact-form', e => {
      e.preventDefault();
      const $button = $(e.currentTarget)
      let errors = 0
      const $form = $('form')
      const $inputs = $form.find('input, textarea')

      $('.form-error').removeClass('show')
      $('.mail-error').removeClass('show')
      $inputs.parent().removeClass('error')
      $inputs.each((k, input) => {
        const $input = $(input)
        if ( $input.val().length <= 0 && $input.hasClass('required')) {
          errors++
          $input.parent().addClass('error')
        }
      })

      if (errors === 0 ) {
        const formData = $form.serializeArray()
        $.ajax({
          method: 'POST',
          url: `/send.php`,
          data: {
            action: 'S2dQU4vW6iizH1VzOgOy',
            form: formData,
            questionnaire: JSON.stringify(this.formData),
            sendWithData: this.sendFormWithData,
          },
          success: (response) => {
            console.log("formdata", this.formData);
            if (response === 'mail sent') {
              this.setStep('last');
            } else {
              $('.mail-error').addClass('show')
            }
          }
        })
      } else {
        $('.form-error').addClass('show')
      }
    })
  }
}

window.onload = () => {
  new Questionnaire
}
